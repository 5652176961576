import React, { useReducer } from "react";
import { useTranslation, Trans } from "react-i18next";
import FormMessage from "./FormMessage";
import FormErrorMessage from "./FormErrorMessage";
import {
  EMAIL_PLACEHOLDER,
  LANGUAGE_DEFAULT,
  NAME_PLACEHOLDER,
  EMAIL_REGEX,
  INPUT_ERROR_STYLE,
} from "./constants";

const initialState = {
  name: "",
  nameError: null,
  email: "",
  emailError: null,
  lang: LANGUAGE_DEFAULT,
};

function formReducer(state, action) {
  switch (action.type) {
    case "CHANGE_NAME": {
      const hasName = Boolean(action.name);
      return {
        ...state,
        name: action.name,
        nameError: hasName ? null : state.nameError,
      };
    }
    case "CHANGE_EMAIL": {
      const hasEmail = Boolean(action.email);
      return {
        ...state,
        email: action.email,
        emailError: hasEmail ? null : state.emailError,
      };
    }
    case "CHANGE_LANG": {
      return {
        ...state,
        lang: action.lang,
      };
    }
    case "SUBMIT": {
      const hasName = Boolean(state.name);
      const isEmailValid = EMAIL_REGEX.test(state.email);
      return {
        ...state,
        nameError: hasName ? null : "Name is missing.",
        emailError: isEmailValid ? null : "Email is invalid.",
      };
    }
    default:
      return state;
  }
}

const SubscriptionForm = ({ status, message, onValidated }) => {
  const { t } = useTranslation();
  const translateEmail = t(EMAIL_PLACEHOLDER);
  const translateName = t(NAME_PLACEHOLDER);
  const [state, dispatch] = useReducer(formReducer, initialState);

  const submit = () => {
    const nameExists = Boolean(state.name);
    const isEmailValid = EMAIL_REGEX.test(state.email);

    dispatch({ type: "SUBMIT" });

    if (nameExists && isEmailValid) {
      onValidated({
        EMAIL: state.email,
        NAME: state.name,
        LANG: state.lang,
      });
    }
  };

  return (
    <>
      <FormErrorMessage
        errorMessages={[state.nameError, state.emailError].filter(Boolean)}
      />
      <div className="form">
        <FormMessage status={status} message={message} />
        <fieldset>
          <input
            type="text"
            placeholder={translateName}
            className="is-black"
            onChange={(e) => {
              dispatch({
                type: "CHANGE_NAME",
                name: e.target.value.trim(),
              });
            }}
            style={state.nameError && INPUT_ERROR_STYLE}
          />
        </fieldset>
        <fieldset>
          <input
            type="email"
            placeholder={translateEmail}
            className="is-black"
            onChange={(e) => {
              dispatch({
                type: "CHANGE_EMAIL",
                email: e.target.value.trim(),
              });
            }}
            style={state.emailError && INPUT_ERROR_STYLE}
          />
        </fieldset>
        <fieldset>
          <select
            required
            defaultValue={state.lang}
            onChange={(e) => {
              dispatch({
                type: "CHANGE_LANG",
                lang: e.target.value,
              });
            }}
            className="select"
            data-testid="language-select"
          >
            <option data-testid="Hr">Hr</option>
            <option data-testid="Srb">Srb</option>
            <option data-testid="Eng">Eng</option>
          </select>
          <label>
            <Trans i18nKey="Select" />
          </label>
        </fieldset>
        <button className="is-black" onClick={submit} data-testid="button">
          <Trans i18nKey="Keep me updated!" />
        </button>

        <p>
          <Trans i18nKey="Team" />
        </p>
      </div>
    </>
  );
};

export default SubscriptionForm;
