import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import SubscriptionForm from "./SubscriptionForm";

const url = `${process.env.REACT_APP_MAILCHIMP_URL}`;
const SubscribeButton = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <SubscriptionForm
        status={status}
        message={message}
        onValidated={(formData) => subscribe(formData)}
      />
    )}
  />
);

export default SubscribeButton;
