import React from "react";
import Header from "./Header";
import Title from "./Title";
import Footer from "./Footer";

const Homepage = () => {
  return (
    <>
      <Title title="Duga" />
      <Header />
      <Footer />
    </>
  );
};
export default Homepage;
