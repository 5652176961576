import React from "react";
import { Trans } from "react-i18next";

const FormMessage = ({ status, message }) => (
  <div data-testid="message">
    {status === "sending" && (
      <div className="message sending">
        <Trans i18nKey="Sending" />
      </div>
    )}
    {status === "error" && (
      <div className="message error">
        {message.includes("already subscribed") ? (
          <Trans i18nKey="Email taken" />
        ) : (
          <span>
            <Trans i18nKey="Please" />
            {/* {message} */}
          </span>
        )}
      </div>
    )}
    {status === "success" && (
      <div className="message success" data-testid="success-message">
        <Trans i18nKey="Thank you! See you soon!" />
      </div>
    )}
  </div>
);

export default FormMessage;
