import React from "react";
import { Switch, Route } from "react-router-dom";
import Homepage from "./components/Homepage";
import Subscribepage from "./components/Subscribepage";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/signup" component={Subscribepage} />
    </Switch>
  );
};

export default Routes;
