import React, { Suspense, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import generateFlag, { colors } from "./components/generateFlag";
import "./styles/app.scss";
import Logo from "./components/Logo";

function Page({ globalIndexColor }) {
  const flag = useRef(null);
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    changeLanguage("hr");
    flag.current.style.backgroundImage = `linear-gradient(124deg, ${colors[globalIndexColor]})`;
  }, []);

  return (
    <Router>
      <div className="flag" ref={flag}></div>
      <nav className="nav">
        <div className="dropdown">
          <button>Lang</button>

          <ul className="dropdownMenu">
            <li className="dropdownItem">
              <button
                onClick={() => changeLanguage("hr")}
                className="dropdownLink"
              >
                🇭🇷
              </button>
            </li>
            <li className="dropdownItem">
              <button
                onClick={() => changeLanguage("srb")}
                className="dropdownLink"
              >
                🇷🇸
              </button>
            </li>
            <li className="dropdownItem">
              <button
                onClick={() => changeLanguage("en")}
                className="dropdownLink"
              >
                🇬🇧
              </button>
            </li>
          </ul>
        </div>
      </nav>
      <Routes />
    </Router>
  );
}

const Loader = ({ globalIndexColor }) => (
  <div className="loader text-center">
    <Logo globalIndexColor={globalIndexColor} />
  </div>
);

export default function App() {
  const globalIndexColor = generateFlag();
  localStorage.setItem("globalIndexColor", globalIndexColor);
  return (
    <Suspense fallback={<Loader globalIndexColor={globalIndexColor} />}>
      <Page globalIndexColor={globalIndexColor} />
    </Suspense>
  );
}
