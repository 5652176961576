import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

import image0 from "./../images/1.jpeg";
import image1 from "./../images/image.jpeg";
import image2 from "./../images/4.jpeg";
import image3 from "./../images/6.jpeg";
import image4 from "./../images/7.jpeg";
import image5 from "./../images/8.jpeg";
import image6 from "./../images/9.jpeg";
import image7 from "./../images/10.jpeg";
import image8 from "./../images/11.jpeg";
import image9 from "./../images/5.jpeg";
import image10 from "./../images/3.jpeg";

const images = [
  image0,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
];

const Header = () => {
  const imageEl = useRef(null);

  useEffect(() => {
    setImage();
  }, []);

  const setImage = () => {
    const index = Math.floor(Math.random() * images.length);
    return (imageEl.current.style.backgroundImage = `url(${images[index]})`);
  };

  return (
    <>
      <header className="header">
        <div className="background-image" ref={imageEl}>
          <div className="layer"></div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-8 offset-sm-1 col-lg-8 offset-lg-2 col-xl-6 text-column">
              <h1>
                <Trans i18nKey="Meet" />
              </h1>

              <p>
                <Trans i18nKey="Homepage" />
              </p>
              <Link to="/signup" className="is-red">
                <Trans i18nKey="Be the first to sign up!" />
              </Link>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
