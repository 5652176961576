import React from "react";
import { Trans } from "react-i18next";
const FormErrorMessage = ({ errorMessages }) => {
  const hasErrorMessages = errorMessages.length > 0;
  return (
    <div className="form-message">
      {hasErrorMessages ? (
        <p className="validation-message">
          {errorMessages.map((msg, i) => (
            <React.Fragment key={i}>
              <Trans i18nKey={msg} />{" "}
            </React.Fragment>
          ))}
        </p>
      ) : (
        <p className="form-description">
          <Trans i18nKey="Fill in the form to stay up to date!" />
        </p>
      )}
    </div>
  );
};

export default FormErrorMessage;
