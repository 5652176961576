import React, { useEffect, useRef } from "react";
import SubscribeButton from "./Form/SubscribeButton";
import { Link } from "react-router-dom";
import generateFlag from "./generateFlag";
import { Trans } from "react-i18next";
import Footer from "./Footer";

const Subscribepage = () => {
  const flag = useRef(null);
  useEffect(() => {
    generateFlag(flag);
  }, []);

  return (
    <>
      <section className="subscribe-page">
        <div className="flag" ref={flag}></div>
        <Link to="/" className="back">
          &#8592; <Trans i18nKey="Back" />
        </Link>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 text-center">
              <h1>
                <Trans i18nKey="Subscribe to find out when our app is ready!" />
              </h1>
              <SubscribeButton />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Subscribepage;
