import React from "react";
import { Trans } from "react-i18next";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          {" "}
          <div className="col-12 col-sm-8 footer-col">
            <span>
              <Trans i18nKey="Help" /> &nbsp;
              <a href="mailto:admin@duga.app">admin@duga.app</a>
            </span>
          </div>
          <div className="col-12 col-sm-4 footer-col text-right-lg">
            <span>&#169; 2020 Duga. </span>
            <span>
              <Trans i18nKey="All rights" />
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
