import React from "react";
import Logo from "./Logo";
import { Link } from "react-router-dom";

const Title = ({ title }) => {
  const globalIndexColor = localStorage.getItem("globalIndexColor");
  return (
    <Link to="/" className="title">
      <Logo globalIndexColor={globalIndexColor} />
      <span> {title}</span>
    </Link>
  );
};
export default Title;
