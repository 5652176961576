export const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const EMAIL_PLACEHOLDER = "Your Email";
export const NAME_PLACEHOLDER = "Your Name";

export const RED = "#f45b69";
export const LANGUAGE_DEFAULT = "Hr";

export const INPUT_ERROR_STYLE = {
  borderBottomColor: RED,
};
